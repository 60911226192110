import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { defaultTheme } from "./theme";
import { config } from "./utils/log";
import { URL_PREFIX_MAPPING, COUNTRY_CONFIGURATION } from "./config";
import {
  createAsyncMessageBundleManager,
  LocalizationContextBuilder,
} from "@amzn/arb-tools";
import arbManifest from "../public/translations/arbManifest";
import { MbmProvider } from "@amzn/react-arb-tools";
import { InputCard } from "./InputCard";
import { StencilProvider } from "@amzn/stencil-react-components/context";
import {
  clearPreviousSession,
  previousSessionExists,
} from "./components/Chat/ChatContainer";
import ChatEndedPage from "./components/Chat/ChatEndedPage";

// disable all console messages for production
console.log = console.warn = console.error = () => {};

const DEFAULT_LOCALE_US = "en-US";
const HREF = new URL(window.location.href);
const LANGUAGE = HREF.searchParams.get("language");
const COUNTRY = HREF.searchParams.get("country");
const JOB_TYPE = HREF.searchParams.get("type");
const CONNECT = HREF.searchParams.get("connect");
const BYPASS_VA = HREF.searchParams.get("bypassVA") ? "true" : "false";
const URL_PREFIX =
  process.env.NODE_ENV === "development" ? "." : URL_PREFIX_MAPPING[COUNTRY];

if (
  !LANGUAGE ||
  !COUNTRY ||
  !COUNTRY_CONFIGURATION[COUNTRY][LANGUAGE][JOB_TYPE]
) {
  window.location = "https://hiring.amazon.com/contact-us#/";
}

const LOCALE = COUNTRY_CONFIGURATION[COUNTRY][LANGUAGE][JOB_TYPE].locale;
const localizationContextProvider = () =>
  new LocalizationContextBuilder()
    .withLocale(LOCALE)
    .withDefaultLocale(DEFAULT_LOCALE_US)
    .build();

const messageBundleManager = createAsyncMessageBundleManager({
  arbManifest,
  localizationContextProvider,
  resolveBundleUrl: (url) =>
    url
      ? `${URL_PREFIX}/translations/` + url
      : `${URL_PREFIX}/translations/translation.json`,
});

let bundle;
let settings;

(function (connect) {
  connect.LogManager && connect.LogManager.updateLoggerConfig(config);
  connect.ChatInterface = connect.ChatInterface || {};
  connect.ChatInterface.init = ({ containerId, ...props }) => {
    if (props.widgetType) {
      config.csmConfig = {
        widgetType: props.widgetType,
      };
    }
    config.features = {
      messageReceipts: {
        shouldSendMessageReceipts: true,
        throttleTime: 5000,
      },
    };
    connect.ChatSession.setGlobalConfig(config);
    ReactDOM.render(
      <MbmProvider mbm={messageBundleManager}>
        <BrowserRouter>
          <App
            {...props}
            bundle={bundle}
            locale={LOCALE}
            virtualAssistantEnable={
              COUNTRY_CONFIGURATION[COUNTRY][LANGUAGE][JOB_TYPE]
                .virtualAssistantEnable
            }
            inputEditorDir={
              COUNTRY_CONFIGURATION[COUNTRY][LANGUAGE][JOB_TYPE]
                .inputEditorDirection
            }
          />
        </BrowserRouter>
      </MbmProvider>,
      document.getElementById(containerId) || document.getElementById("root")
    );
  };

  connect.ChatInterface.getCurrentTheme = () => {
    return defaultTheme;
  };

  window.connect = connect;
})(window.connect || {});

async function initChatApp(emailAddress) {
  bundle = await messageBundleManager.getBundle("strings");
  settings = await fetch(`${URL_PREFIX}/settings.json`).then((result) =>
    result.json()
  );
  connect.ChatInterface.init({
    containerId: "root", // This is the id of the container where you want the widget to reside
  });

  const contactAttributes = {
    country: COUNTRY_CONFIGURATION[COUNTRY][LANGUAGE][JOB_TYPE].country,
    language: COUNTRY_CONFIGURATION[COUNTRY][LANGUAGE][JOB_TYPE].language,
    business: COUNTRY_CONFIGURATION[COUNTRY][LANGUAGE][JOB_TYPE].business,
    channel: COUNTRY_CONFIGURATION[COUNTRY][LANGUAGE][JOB_TYPE].channel,
    candidateEmailAddress: emailAddress,
    virtualAssistantModuleBypass: BYPASS_VA,
  };

  connect.ChatInterface.initiateChat(
    {
      name: emailAddress,
      region: settings.region,
      apiGatewayEndpoint: settings.apiGatewayEndpoint,
      contactAttributes: JSON.stringify(contactAttributes),
      featurePermissions: {
        ATTACHMENTS: false, // this is the override flag from user for attachments
      },
      supportedMessagingContentTypes: "text/plain", // enable rich messaging
      // we only need the last part of the contact flow
      // https://docs.aws.amazon.com/connect/latest/APIReference/API_StartChatContact.html
      contactFlowId: settings.contactFlowId.split("/")[3],
      instanceId: settings.instanceId,
      locale: LOCALE,
      country: COUNTRY,
      language: LANGUAGE,
      jobType: JOB_TYPE,
    },
    successHandler,
    failureHandler
  );
}

function successHandler(chatSession) {
  console.log("success!");
  document.getElementById("section-chat").style.opacity = 1;

  chatSession.onChatDisconnected(function (_data, bundle) {
    document.getElementById("root")?.remove();

    ReactDOM.render(
      <ChatEndedPage bundle={bundle} />,
      document.getElementById("chat-closure-message")
    );
  });
}

function failureHandler(error) {
  console.log("There was an error: ");
  console.log(error);
}

if (previousSessionExists(LANGUAGE, COUNTRY, JOB_TYPE)) {
  // continue previous chat session
  initChatApp("").then((r) => {});
} else {
  // clear the cache and start a new chat session
  clearPreviousSession();
  ReactDOM.render(
    <MbmProvider mbm={messageBundleManager}>
      <StencilProvider>
        <InputCard
          initChatApp={initChatApp}
          inputEditorDir={
            COUNTRY_CONFIGURATION[COUNTRY][LANGUAGE][JOB_TYPE]
              .inputEditorDirection
          }
          language={LANGUAGE}
          country={COUNTRY}
          jobType={JOB_TYPE}
          urlPrefix={URL_PREFIX}
          connect={CONNECT}
        />
      </StencilProvider>
    </MbmProvider>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
