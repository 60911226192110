import React from "react";
import { StencilProvider } from "@amzn/stencil-react-components/context";
import { Col } from "@amzn/stencil-react-components/layout";
import { H1, Text } from "@amzn/stencil-react-components/text";
import { getText } from "../../utils/pantherHelper";

class ChatEndedPage extends React.Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
  }

  componentDidMount() {
    document.title = getText(this.props.bundle, "chat-closure-page-title");
    this.titleRef.current?.focus();
  }

  render() {
    const { bundle } = this.props;
    return (
      <StencilProvider>
        <Col alignItems={"center"} padding={"1rem"}>
          <H1
            ref={this.titleRef}
            tabIndex="-1"
            dataTestId={"chat-closure-prompt-title"}
          >
            {getText(bundle, "chat-closure-prompt-title")}
          </H1>
          <Text>{getText(bundle, "chat-closure-prompt")}</Text>
        </Col>
      </StencilProvider>
    );
  }
}

export default ChatEndedPage;
