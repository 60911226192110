import React, { useState } from "react";
import PT from "prop-types";
import {
  BinaryRating,
  BinaryRatingFeedbackLayout,
  BinaryRatingVariant,
  BinaryRatingValue,
} from "@amzn/stencil-react-components/feedback";
import { getText } from "../../../utils/pantherHelper";
import { Label } from "@amzn/stencil-react-components/text";
import { Col, View } from "@amzn/stencil-react-components/layout";
import {
  LiveAnnouncer,
  LiveRegion,
  LiveRegionRole,
} from "@amzn/stencil-react-components/live-announcer";

Feedback.propTypes = {
  closeFeedbackModal: PT.func.isRequired,
  sendFeedback: PT.func.isRequired,
};

export default function Feedback({ closeFeedbackModal, sendFeedback, bundle }) {
  const [hasErrors, setHasErrors] = useState(false);
  const [shouldShowMessageBanner, setShouldShowMessageBanner] = useState(false);
  const [shouldShowFeedbackCard, setShouldShowFeedbackCard] = useState(false);

  const feedbackRatingMessage = getText(bundle, "feedback-rating-message");
  const feedbackNoHelp = getText(bundle, "feedback-no-help");
  const feedbackIncorrectHelp = getText(bundle, "feedback-incorrect-help");
  const feedbackRating = getText(bundle, "feedback-rating");
  const feedbackRatingReason = getText(bundle, "feedback-rating-reason");
  const feedbackHelpTitle = getText(bundle, "feedback-help-title");
  const feedbackDisclaimer = getText(bundle, "feedback-disclaimer");
  const feedbackThanks = getText(bundle, "feedback-thanks");

  const FeedbackRatingReasonElicitation = () => {
    return (
      <View>
        <Label aria-required="true">
          {getText(bundle, "feedback-rating-reason-elicitation")}{" "}
          <strong style={{ color: "red" }} aria-hidden>
            *
          </strong>{" "}
        </Label>
        <span style={{ visibility: "hidden" }} aria-label="Required"></span>
      </View>
    );
  };
  const feedbackNoToggleDescription = getText(
    bundle,
    "feedback-no-toggle-description"
  );

  const feedbackReasons = [feedbackNoHelp, feedbackIncorrectHelp];

  const handleRatingChange = (_, value) => {
    setHasErrors(false);
    setShouldShowFeedbackCard(value === BinaryRatingValue.No);
    setShouldShowMessageBanner(value === BinaryRatingValue.Yes);
    if (value === BinaryRatingValue.Yes) {
      sendFeedback({
        text: `${feedbackRating} (+)`,
      });
    }
    setMessage("Thank you for your feedback");
  };
  const handleSubmit = (data) => {
    if (
      typeof data.selectedOptions === "undefined" ||
      JSON.stringify(data.selectedOptions).length === 0
    ) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
      setShouldShowFeedbackCard(false);
      setShouldShowMessageBanner(true);
      sendFeedback({
        text: `${feedbackRating} (-) ${feedbackRatingReason} (${
          feedbackReasons[data.selectedOptions]
        })`,
      });
    }
  };
  const handleHideCard = () => {
    setHasErrors(false);
    setShouldShowFeedbackCard(false);
    sendFeedback({
      text: `${feedbackRating} (-)`,
    });
    closeFeedbackModal();
  };

  const feedbackThankYouMessage = "";
  const [message, setMessage] = useState(feedbackThankYouMessage);

  return (
    <Col alignItems={"center"}>
      <LiveAnnouncer height={"0px"}>
        <LiveRegion aria-live="assertive" role={LiveRegionRole.Status}>
          <div
            style={{
              position: "absolute",
              width: "1px",
              height: "1px",
              padding: "0",
              margin: "-1px",
              overflow: "hidden",
              border: "0",
            }}
          >
            {message}
          </div>
        </LiveRegion>
      </LiveAnnouncer>
      <BinaryRating
        variant={BinaryRatingVariant.Inline}
        feedbackLayout={BinaryRatingFeedbackLayout.Modal}
        feedbackModalProps={{
          title: feedbackHelpTitle,
          disclaimerText: feedbackDisclaimer,
          onClose: handleHideCard,
        }}
        feedbackCardProps={{
          hasErrors,
          title: <FeedbackRatingReasonElicitation />,
          options: feedbackReasons,
        }}
        messageBannerProps={
          shouldShowMessageBanner
            ? {
                autoDismissAfter: 2000,
                content: feedbackThanks,
                onDismissed: closeFeedbackModal,
              }
            : undefined
        }
        noDescriptionText={feedbackNoToggleDescription}
        onCancelClick={handleHideCard}
        onLaterClick={handleHideCard}
        onRatingChange={handleRatingChange}
        onSubmit={handleSubmit}
        shouldShowFeedbackCard={shouldShowFeedbackCard}
        titleText={feedbackRatingMessage}
      />
    </Col>
  );
}
