export const URL_PREFIX_MAPPING = {
  MENA: "/chat/mena",
  "Costa Rica": "/chat/am",
  Colombia: "/chat/am",
  Brasil: "/chat/am",
  "United States": "/chat/am",
  Canada: "/chat/am",
  Mexico: "/chat/am",
  "United Kingdom": "/chat/mena",
  Germany: "/chat/mena",
};

export const COUNTRY_CONFIGURATION = {
  MENA: {
    English: {
      "Warehouse / Fulfillment / Delivery": {
        locale: "en-US",
        inputEditorDirection: "ltr",
        country: "MENA",
        language: "English",
        business: "WAREHOUSE",
        channel: "CHAT",
        virtualAssistantEnable: false,
      },
    },
    Arabic: {
      "المستودع / الشحن / مندوب توصيل": {
        locale: "ar-AE",
        inputEditorDirection: "rtl",
        country: "MENA",
        language: "Arabic",
        business: "WAREHOUSE",
        channel: "CHAT",
        virtualAssistantEnable: false,
      },
    },
  },
  "Costa Rica": {
    Spanish: {
      "Servicio al Cliente": {
        locale: "es-MX",
        inputEditorDirection: "ltr",
        country: "Costa Rica",
        language: "Spanish",
        business: "CUSTOMER_SERVICE",
        channel: "CHAT",
        virtualAssistantEnable: false,
      },
    },
  },
  Colombia: {
    Spanish: {
      "Servicio al Cliente": {
        locale: "es-MX",
        inputEditorDirection: "ltr",
        country: "Colombia",
        language: "Spanish",
        business: "CUSTOMER_SERVICE",
        channel: "CHAT",
        virtualAssistantEnable: false,
      },
    },
  },
  Brasil: {
    Português: {
      "Serviço ao Cliente": {
        locale: "pt-BR",
        inputEditorDirection: "ltr",
        country: "Brazil",
        language: "Portuguese",
        business: "CUSTOMER_SERVICE",
        channel: "CHAT",
        virtualAssistantEnable: false,
      },
    },
  },
  "United States": {
    English: {
      "Warehouse / Fulfillment / Delivery / Shopper": {
        locale: "en-US",
        inputEditorDirection: "ltr",
        country: "United States",
        language: "English",
        business: "WAREHOUSE",
        channel: "CHAT",
        virtualAssistantEnable: true,
      },
      "Contact Center / Virtual Contact Center": {
        locale: "en-US",
        inputEditorDirection: "ltr",
        country: "United States",
        language: "English",
        business: "CUSTOMER_SERVICE",
        channel: "CHAT",
        virtualAssistantEnable: false,
      },
    },
    Spanish: {
      "Almacén (Warehouse)": {
        locale: "es-US",
        inputEditorDirection: "ltr",
        country: "United States",
        language: "Spanish",
        business: "WAREHOUSE",
        channel: "CHAT",
        virtualAssistantEnable: false,
      },
    },
  },
  Canada: {
    English: {
      "Warehouse / Fulfillment": {
        locale: "en-CA",
        inputEditorDirection: "ltr",
        country: "Canada",
        language: "English",
        business: "WAREHOUSE",
        channel: "CHAT",
        virtualAssistantEnable: false,
      },
    },
  },
  Mexico: {
    Spanish: {
      Almacén: {
        locale: "es-MX",
        inputEditorDirection: "ltr",
        country: "Mexico",
        language: "Spanish",
        business: "WAREHOUSE",
        channel: "CHAT",
        virtualAssistantEnable: false,
      },
    },
  },
  "United Kingdom": {
    English: {
      "Warehouse / Fulfillment": {
        locale: "en-GB",
        inputEditorDirection: "ltr",
        country: "PLACE_HOLDER",
        language: "PLACE_HOLDER",
        business: "PLACE_HOLDER",
        channel: "PLACE_HOLDER",
        virtualAssistantEnable: false,
      },
    },
  },
  Germany: {
    English: {
      "Warehouse / Fulfillment": {
        locale: "en-US",
        inputEditorDirection: "ltr",
        country: "PLACE_HOLDER",
        language: "PLACE_HOLDER",
        business: "PLACE_HOLDER",
        channel: "PLACE_HOLDER",
        virtualAssistantEnable: false,
      },
    },
    German: {
      Versandmitarbeiter: {
        locale: "de-DE",
        inputEditorDirection: "ltr",
        country: "PLACE_HOLDER",
        language: "PLACE_HOLDER",
        business: "PLACE_HOLDER",
        channel: "PLACE_HOLDER",
        virtualAssistantEnable: false,
      },
    },
  },
};
