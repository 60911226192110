// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import * as React from "react";
import styled from "styled-components";
import PT from "prop-types";
import { CONTACT_STATUS } from "../../../constants/global";
import { getText } from "../../../utils/pantherHelper";
import { Modal } from "@amzn/stencil-react-components/modal";
import { Text } from "@amzn/stencil-react-components/text";
import { Button } from "@amzn/stencil-react-components/button";
import { ButtonVariant } from "@amzn/stencil-react-components/button";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import Feedback from "../Feedback/Feedback";

export const ACTION_BAR_HEIGHT = "100%";

const Actions = styled.div`
  background: ${(props) => props.theme.palette.dustyGray};
  height: ${ACTION_BAR_HEIGHT};
`;

const FooterWrapper = styled.div`
  order: 3;
  height: 10%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  align-items: center;
  > button {
    min-width: 85px;
    margin: ${(props) => props.theme.spacing.mini};
    font-weight: bold;
  }
`;

const ActionButton = styled(Button)`
  margin: ${(props) => props.theme.spacing.small};
  width: ${(props) => (props.col ? 100 / props.col - 7 + "%" : "")};
`;

function createMarkup(content) {
  return { __html: content };
}

export default class ChatActionBar extends React.Component {
  constructor() {
    super();
    if (window.connect && window.connect.LogManager) {
      this.logger = window.connect.LogManager.getLogger({
        prefix: "ChatInterface-ChatActionBar",
      });
    }
    this.state = {
      openConfirmationModal: false,
      openFeedbackModal: false,
      messageRating: undefined,
      messageRatingReason: undefined,
    };
  }

  static propTypes = {
    contactStatus: PT.string.isRequired,
    sendFeedback: PT.func.isRequired,
    isVirtualAssistant: PT.bool.isRequired,
    onEndChat: PT.func,
    onClose: PT.func,
    footerConfig: PT.object,
  };

  static defaultProps = {
    onEndChat: () => {},
    onClose: () => {},
    footerConfig: {},
  };

  componentDidMount() {
    this.logger && this.logger.info("Component mounted.");
  }
  render() {
    const {
      contactStatus,
      onEndChat,
      onClose,
      footerConfig,
      sendFeedback,
    } = this.props;

    const openConfirmationModal = () => {
      closeFeedbackModal();
    };

    const closeConfirmationModal = () => {
      this.setState({
        ...this.state,
        openFeedbackModal: false,
        openConfirmationModal: false,
      });
    };

    const openFeedbackModal = () => {
      this.setState({
        ...this.state,
        openFeedbackModal: true,
        openConfirmationModal: false,
      });
    };

    const closeFeedbackModal = () => {
      this.setState({
        ...this.state,
        openFeedbackModal: false,
        openConfirmationModal: true,
      });
    };

    if (footerConfig.render) {
      const content = footerConfig.render(this.props);
      return footerConfig.isHTML ? (
        <FooterWrapper dangerouslySetInnerHTML={createMarkup(content)} />
      ) : (
        <FooterWrapper>{content}</FooterWrapper>
      );
    }

    const confirmationModal = (
      <Modal
        isOpen={this.state.openConfirmationModal}
        close={closeConfirmationModal}
      >
        <Col
          backgroundColor="neutral0"
          gridGap="2rem"
          padding="2rem"
          alignItems="center"
        >
          <Text>{getText(this.props.bundle, "end-chat-confirmation")}</Text>
          <Row gridGap="1rem">
            <Button
              onClick={closeConfirmationModal}
              variant={ButtonVariant.Tertiary}
            >
              {getText(this.props.bundle, "back-chat-button")}
            </Button>
            <Button
              isDestructive
              onClick={onEndChat}
              variant={ButtonVariant.Secondary}
            >
              {getText(this.props.bundle, "end-chat-button")}
            </Button>
          </Row>
        </Col>
      </Modal>
    );

    const feedbackModal = (
      <Modal isOpen={this.state.openFeedbackModal} close={closeFeedbackModal}>
        <Col
          backgroundColor="neutral0"
          gridGap="2rem"
          padding="2rem"
          alignItems="center"
        >
          <Feedback
            closeFeedbackModal={closeFeedbackModal}
            sendFeedback={sendFeedback}
            bundle={this.props.bundle}
          />
        </Col>
      </Modal>
    );

    return (
      <FooterWrapper>
        <Actions>
          <ButtonWrapper>
            {(contactStatus === CONTACT_STATUS.CONNECTED ||
              contactStatus === CONTACT_STATUS.CONNECTING) && (
              <React.Fragment>
                <ActionButton
                  col="2"
                  type="default"
                  variant={ButtonVariant.Secondary}
                  onClick={
                    this.props.isVirtualAssistant
                      ? openFeedbackModal
                      : openConfirmationModal
                  }
                >
                  <span>{getText(this.props.bundle, "end-chat-button")}</span>
                </ActionButton>
              </React.Fragment>
            )}

            {contactStatus === CONTACT_STATUS.ENDED && (
              <React.Fragment>
                <ActionButton col="2" type="default" onClick={onClose}>
                  <span>{getText(this.props.bundle, "close-chat-button")}</span>
                </ActionButton>
              </React.Fragment>
            )}
            {this.props.isVirtualAssistant && feedbackModal}
            {confirmationModal}
          </ButtonWrapper>
        </Actions>
      </FooterWrapper>
    );
  }
}
