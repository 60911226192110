// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { Component } from "react";
import styled from "styled-components";
import Chat from "./Chat";
import ChatSession from "./ChatSession";
import { initiateChat } from "./ChatInitiator";
import EventBus from "./eventbus";
import "./ChatInterface";
import { CHAT_FEATURE_TYPES } from "./constants";
import { ContentType } from "./datamodel/Model";
import { getText } from "../../utils/pantherHelper";
import { SpinnerSize } from "@amzn/stencil-react-components/dist/submodules/spinner/interfaces";
import { Spinner } from "@amzn/stencil-react-components/spinner";
import { Col } from "@amzn/stencil-react-components/layout";

const MessageBoxFail = styled.div`
  padding: 10;
  background-color: red;
`;

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.globals.basePadding};
  height: 100%;
`;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function previousSessionExists(language, country, jobType) {
  return (
    localStorage.getItem("Language") === language &&
    localStorage.getItem("Country") === country &&
    localStorage.getItem("JobType") === jobType &&
    localStorage.getItem("ContactId") &&
    localStorage.getItem("ParticipantId") &&
    localStorage.getItem("ParticipantToken")
  );
}

export function clearPreviousSession() {
  localStorage.removeItem("Language");
  localStorage.removeItem("Country");
  localStorage.removeItem("JobType");
  localStorage.removeItem("ContactId");
  localStorage.removeItem("ParticipantId");
  localStorage.removeItem("ParticipantToken");
}

function cacheSession(input, chatDetails) {
  localStorage.setItem("Language", input.language);
  localStorage.setItem("Country", input.country);
  localStorage.setItem("JobType", input.jobType);
  localStorage.setItem("ContactId", chatDetails.startChatResult.ContactId);
  localStorage.setItem(
    "ParticipantId",
    chatDetails.startChatResult.ParticipantId
  );
  localStorage.setItem(
    "ParticipantToken",
    chatDetails.startChatResult.ParticipantToken
  );
}

function reloadPageIfInsufficientDataToCreateNewSession(input) {
  if (input.name === "") {
    clearPreviousSession();
    window.location.reload();
  }
}

class ChatContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatSession: null,
      composerConfig: {},
      status: "NotInitiated",
    };

    this.submitChatInitiationHandler = this.initiateChatSession.bind(this);
    EventBus.on("initChat", this.initiateChatSession.bind(this));
    if (window.connect && window.connect.LogManager) {
      this.logger = window.connect.LogManager.getLogger({
        prefix: "ChatInterface-ChatContainer",
      });
    }
  }

  componentWillUnmount() {
    EventBus.off(this.submitChatInitiationHandler);
  }

  initiateChatSession(chatDetails, success, failure) {
    const logContent = {
      contactFlowId: chatDetails.contactFlowId
        ? chatDetails.contactFlowId
        : null,
      instanceId: chatDetails.instanceId ? chatDetails.instanceId : null,
      region: chatDetails.region ? chatDetails.region : null,
      stage: chatDetails.stage ? chatDetails.stage : null,
      featurePermissions: chatDetails.featurePermissions
        ? chatDetails.featurePermissions
        : null,
      apiGatewayEndpoint: chatDetails.apiGatewayEndpoint
        ? chatDetails.apiGatewayEndpoint
        : null,
    };
    this.logger && this.logger.info("Chat session meta data:", logContent);
    this.submitChatInitiation(chatDetails, success, failure);
  }

  /**
   * Initiate a chat in 2 steps.
   *
   * Step 1: Create a chat session within Amazon Connect (more details in ChatInitiator.js)
   * This step provides us with a 'chatDetails' object that contains among others:
   * - Auth Token
   * - Websocket endpoint
   * - ContactId
   * - ConnectionId
   *
   * Step 2: Connect to created chat session.
   * Open a websocket connection via Chat.JS (more details in ChatSession.js)
   *
   * @param {*} input
   * @param {*} success
   * @param {*} failure
   */
  async submitChatInitiation(input, success, failure) {
    this.setState({ status: "Initiating" });

    try {
      const [chatSession, chatDetails] = await this.continueChatSession(input);
      const attachmentsEnabled =
        (input.featurePermissions &&
          input.featurePermissions[CHAT_FEATURE_TYPES.ATTACHMENTS]) ||
        (chatDetails.featurePermissions &&
          chatDetails.featurePermissions[CHAT_FEATURE_TYPES.ATTACHMENTS]);
      const richMessagingEnabled =
        typeof input.supportedMessagingContentTypes === "string"
          ? input.supportedMessagingContentTypes
              .split(",")
              .includes(ContentType.MESSAGE_CONTENT_TYPE.TEXT_MARKDOWN)
          : false;

      this.setState({
        status: "Initiated",
        chatSession: chatSession,
        composerConfig: {
          attachmentsEnabled,
          richMessagingEnabled,
        },
        displayChat: false,
      });

      success && success(chatSession);

      await sleep(5000);
      this.setState({
        displayChat: true,
      });
    } catch (error) {
      this.setState({ status: "InitiateFailed" });
      failure && failure(error);
    }
  }

  async continueChatSession(input) {
    try {
      const contactId = localStorage.getItem("ContactId");
      const participantId = localStorage.getItem("ParticipantId");
      const participantToken = localStorage.getItem("ParticipantToken");
      if (!contactId || !participantId || !participantToken) {
        throw new Error("Previous contact session info not found");
      }
      const chatDetails = {
        startChatResult: {
          ContactId: localStorage.getItem("ContactId"),
          ParticipantId: localStorage.getItem("ParticipantId"),
          ParticipantToken: localStorage.getItem("ParticipantToken"),
          ContinuedFromContactId: null,
        },
      };
      return [
        await this.openChatSession(
          chatDetails,
          input.name,
          input.region,
          input.stage
        ),
        chatDetails,
      ];
    } catch (error) {
      // Unable to reload previous chat session
      // 1. Previous contact was already closed
      // 2. Invalid contactId/participantId/participantToken
      reloadPageIfInsufficientDataToCreateNewSession(input);
      let newChatDetails = await initiateChat(input);
      cacheSession(input, newChatDetails);

      return [
        await this.openChatSession(
          newChatDetails,
          input.name,
          input.region,
          input.stage
        ),
        newChatDetails,
      ];
    }
  }

  openChatSession(chatDetails, name, region, stage) {
    const chatSession = new ChatSession(chatDetails, name, region, stage);
    chatSession.onChatClose(() => {
      EventBus.trigger("endChat", {});
    });
    return chatSession.openChatSession().then(() => {
      return chatSession;
    });
  }

  resetState = () => {
    this.setState({ status: "NotInitiated", chatSession: null });
    this.logger && this.logger.info("Chat session is reset");
  };

  render() {
    if (
      "NotInitiated" === this.state.status ||
      "Initiating" === this.state.status
    ) {
      return (
        <Col height={"100%"} alignItems={"center"} justifyContent={"center"}>
          <Spinner
            aria-live={"assertive"}
            aria-atomic={true}
            size={SpinnerSize.Small}
          />
        </Col>
      );
    }

    if ("InitiateFailed" === this.state.status) {
      return (
        <Wrapper>
          <MessageBoxFail>
            {getText(this.props.bundle, "initialization-failure")}
          </MessageBoxFail>
        </Wrapper>
      );
    }
    return (
      <>
        {this.state.displayChat ? null : (
          <Col height={"100%"} alignItems={"center"} justifyContent={"center"}>
            <Spinner
              aria-live={"assertive"}
              aria-atomic={true}
              size={SpinnerSize.Small}
            />
          </Col>
        )}
        <Chat
          displayChat={this.state.displayChat}
          chatSession={this.state.chatSession}
          composerConfig={this.state.composerConfig}
          onEnded={this.resetState}
          {...this.props}
        />
        <div
          id={"aria-message"}
          aria-live={"assertive"}
          aria-atomic={true}
          style={{
            position: "absolute",
            width: "1px",
            height: "1px",
            padding: "0",
            margin: "-1px",
            overflow: "hidden",
            border: "0",
          }}
        ></div>
      </>
    );
  }
}

export default ChatContainer;
