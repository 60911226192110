// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from "react";
import PT from "prop-types";
import { ContentType } from "../../datamodel/Model";
import { getFormattedText, getText } from "../../../../utils/pantherHelper";
import { LOCAL_STORAGE_KEYS } from "../../../../constants/global";

function getSystemMessage(bundle, textName, name) {
  if (name.includes("@")) {
    // candidate display name contains @
    return getText(bundle, textName);
  } else {
    return getFormattedText(bundle, textName + "-agent", {
      name: name,
    });
  }
}
export class SystemMessage extends React.PureComponent {
  static propTypes = {
    messageDetails: PT.object.isRequired,
  };

  static defaultProps = {};

  getMessageText = () => {
    console.log("SystemMessage getMessageText");
    console.log(this.props);
    const name = this.props.messageDetails.displayName;
    switch (this.props.messageDetails.content.type) {
      case ContentType.EVENT_CONTENT_TYPE.PARTICIPANT_JOINED:
        localStorage.setItem(LOCAL_STORAGE_KEYS.USER_HAS_JOINED_CHAT, "true"); // This marks the entry of the user into the chat
        return getSystemMessage(this.props.bundle, "join-chat-message", name);
      case ContentType.EVENT_CONTENT_TYPE.PARTICIPANT_LEFT:
        localStorage.setItem(LOCAL_STORAGE_KEYS.USER_HAS_JOINED_CHAT, "false"); // This marks the exit of the user from the chat
        return getSystemMessage(this.props.bundle, "left-chat-message", name);
      case ContentType.EVENT_CONTENT_TYPE.CHAT_ENDED:
        return getText(this.props.bundle, "end-chat-message");

      default:
        return "";
    }
  };

  render() {
    return <>{this.getMessageText()}</>;
  }
}
